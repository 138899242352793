<template>
  <div style="padding: 10px; padding-top: 44px">
    <van-nav-bar
      title="全游云电脑用户服务协议"
      left-arrow
      @click-left="$router.go(-1)"
      fixed
    />
    <p style="margin: 5px 0px; text-align: center">
      <span style="font-family: 楷体; font-size: 14px; font-weight: bold"
        >全游云电脑产品用户服务协议</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >欢迎使用杭州子默网络科技有限公司（以下称“公司”或“全游云电脑产品方”）旗下的全游云电脑产品及相关各项服务（以下称“云电脑”）。用户（以下或称“您”或“用户”）在使用云电脑产品之前，请仔细阅读本《全游云电脑产品用户服务协议》（以下称“协议”）中的所有内容，特别是其中免除或者限制责任的免责条款和对您的权利限制条款。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >名词释义：</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >用户：</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >是指使用全游云电脑产品的个人。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >全游云电脑服务：</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >是指全游云电脑产品方为您提供的整体服务方案，包括云端资源、传输协议和云终端</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >云电脑相关硬件设备：</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >是指您在使用</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >公司</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >自主研发的知识产权成果“云电脑（ZCube1 Max）”</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >时所需的包括但不限于显示器、鼠标、键盘、耳机以及相关的数据线等相关设施、设备</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >账</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >号</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >：</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >是指您根据全游云电脑方的注册规则与要求完成注册的用户信息。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >支持服务：</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >是指您在安装、使用、升级、技术故障的情形下，全游云电脑</span
      ><span style="font-family: 楷体; font-size: 10.5px">为</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >用户提供的技术支持或服务。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第一条：用户须知</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >1.1使用资格：您需要达到18周岁方可使用全游云电脑产品服务，如您为无民事行为能力人或为限制民事行为能力人或者不满18周岁，请告知您的监护人，并在您监护人的指导下阅读和判断是否同意本服务条款和使用我们的服务；否则您无权使用全游云电脑产品服务。</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >&#xa0;</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第二条：账号注册</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >2.1本产品账号是适用全游云电脑的前提，您在阅读并同意本服务条款并成功完成注册后，即默认创建新的账号，成为全游云电脑产品的注册用户。您应当按注册页面引导填写信息，后完成注册。注册时您应提供及时、详尽及准确的个人资料，并不断更新注册资料；如果因注册信息不真实或更新不及时而引发的相关问题，全游云电脑产品不负任何责任。用户注册成功后将获得一个全游云电脑产品账号，用户可以通过手机验证码或扫码登录方式完成注册。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >2.2注册后的账号由您负责保管，如果因非全游云电脑方原因导致账号脱离控制，导致您自身、全游云电脑产品或任何第三方损害的，您将承担全部责任。同时您不应将其账号转让、出售或出借予他人使用，若您授权他人使用账号，应对被授权人在该账号下发生所有行为承担全部责任。</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >&#xa0;</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第三条：使用规则</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.1全游云电脑产品方在此郑重提醒您，您使用全游云电脑产品时应遵守包括但不限于如下内容：</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.1遵守中华人民共和国法律和法规以及公共秩序、社会道德风尚和信息真实性等规范；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.2遵守所有与网络服务有关的网络协议、规定和程序；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.3不得通过全游云电脑产品散布和传播侵权、反动、色情、淫秽、虚假、诽谤、骚扰、违法或其他违反国家法律、法规的信息以及以任何非法目的而使用全游云电脑产品；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.4不得利用全游云电脑产品进行任何可能对互联网或移动网正常运转造成不利影响的行为；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.5不得实施侵犯全游云电脑产品和其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.6不得利用全游云电脑产品提供的服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的等其他任何非法信息资料；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.7不得利用全游云电脑产品进行恶意挂机、无操作恶意占用机器、游戏开挂等行为；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >3.1.8其他应当遵循的情形</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.2您理解并同意，全游云电脑产品仅提供相关的产品服务，除此之外与相关网络服务有关的硬件设备（包括</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >但不限于显示器、鼠标、键盘、耳机以及相关的数据线等相关设施、设备</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >）均应由您自行负担；且您提供的硬件设备应满足全游云电脑适配的最低需求；若因您硬件设备低于全游云电脑适配需求而造成的任何风险，公司对此不承担任何风险与法律责任。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.3您理解并同意，就您获得的产品或账号不得向第三方以包括但不限于出借、二次销售、转租、许可等方式</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >进行</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >有偿或无偿的交易，否则由此产生的风险、产品及网络安全、以及由此或其他违约行为给全游云电脑产品方或其他任何第三方造成损失等法律责任均由您自担。</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >&#xa0;</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.4您理解并同意，若您使用全游云电脑时不符合使用规则的，全游云电脑产品方有权以包括但不限于通知、举报、系统预警等方式告知您且有权做出独立判断，在无事先通知您的情况下终止向您提供部分或全部服务。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.5您理解并同意，全游云电脑产品方有权对您使用全游云电脑产品提供服务的情况进行监督，如经由通知、举报等途径发现您在使用全游云电脑产品时违反本服务条款的规定或网络监管要求的，全游云电脑产品方有权要求您改正或直接采取一切必要的措施（包括但不限于暂停或终止您使用网络服务等）以减轻您不当行为造成的影响、风险及法律责任。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.6您理解并同意就您使用全游云电脑产品时，因全游云电脑产品中有接入第三方软件，如产品计费系统、无盘系统时应遵守该软件的使用要求及监管要求。如因使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，全游云电脑产品方不承担任何责任；同时全游云电脑产品方不对第三方软件或技术提供客服支持，若用户需要获取支持，请与第三方联系。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.7您理解并同意，全游云电脑产品同大多数互联网软件产品一样，可能会受多种因素影响，包括但不限于用户原因、网络服务质量、社会环境等；也可能会受各种安全问题的侵扰，包括但不限于他人非法利用您的资料，进行现实中的骚扰；您下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您的终端设备信息和数据安全，继而影响本软件的正常使用等。因此，您应加强信息安全及个人信息的保护意识，以免遭受损失。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.8您理解并同意，全游云电脑产品方不承诺、不保证、不负责对您使用全游云电脑产品时上传的、保存的内容负责，也不对您在使用全游云电脑产品时对外发布的无论是公开还是私密的信息负责；若您以</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >对外发布、</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >上传、保存等方式存储的，所产生的风险、责任、法律后果均由您独自承担。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.9您理解并同意，若因您个人原因未及时终止当次云电脑使用而导致云电脑服务持续计费的，因持续计费所产生的费用消耗或时长消耗，由您自行承担。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >3.10您理解并确认，云电脑的服务收费模式是基于您已经购买的时长的，单次服务的结束是以您直接通过云电脑下机或者其他方式确认下机作为时间点。从您开始确认使用至结束服务的整个时间段，我们都将收取服务费用。在使用过程中，您可以选择购买额外的时长来延长当次的云电脑服务。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第四条 产品功能的完善、升级及技术支持</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >4.1
        您理解并同意全游云电脑方享有随时完善、升级云电脑产品的权利，具体完善、升级的时间以公司发布或通知的时间为准。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >4.2
        您理解并同意提供给您的修改、完善、升级后版本将被视为提供全游云电脑产品的一部分并且要受到本协议的约束、制约。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >4.3
        全游云电脑产品方为您提供全游云电脑的有关支持服务，提供给用户的技术支持、服务视为全游云电脑产品方提供服务的组成部分。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >4.4
        您理解并同意您在使用全游云电脑产品时非基于全游云电脑产品原因而发生的其他硬件设备故障不属于全游云电脑产品方技术支持、服务范围。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第五条：</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >服务变更、中断或终止</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px">5.1</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >基于网络服务的特殊性，您同意全游云电脑产品方有权根据业务发展情况随时变更、中断或终止部分或全部的网络服务，无需对任何您或任何第三方承担任何责任；我们会在采取行动前尽全力通知您。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px">5.2</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >您理解并同意全游云电脑产品需要定期或不定期地对全游云电脑产品进行维护或者升级，如因此类情况而造成网络服务在合理时间内的中断，全游云电脑产品无须为此承担任何责任，但全游云电脑产品方应尽可能事先进行通告。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >如发生下列任何一种情形，全游云电脑产品方有权随时中断或终止向您提供本服务条款项下的服务而无需对您或任何第三方承担任何责任：</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.1您提供的资料信息不真实或者向全游云电脑方提供虚假合作和误导信息或未经全游云电脑方同意对外转让、出租的；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.2您违反本服务条款中的使用规则，包括任何约定、政策或者条款；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.3您违反法律法规的规定或侵犯任何第三人的合法利益；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.4为执行司法或者政府机关提出的要求，或者紧急的系统、程序更新、升级；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.5因其他无法预料的安全、技术和业务原因；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.6因您提供的硬件设备与云电脑产品不兼容等情形造成的；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.7因运营商网络设备故障导致无法继续使用；</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >5.3.8不可抗力原因及其他原因导致全游云电脑产品无法使用情形的。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第六条：知识产权</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >6.1
        您理解并同意全游云电脑产品方向您提供与全游云电脑产品相关的一切版权、商标权、专利权、商业秘密等知识产权，以及与软件相关的所有信息内容，包括但不限于：文字、声音、照片、视频、图表，及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等均属于全游云电脑产品方及相关权利人所有，均受中华人民共和国的著作权法、商标法、专利法、反不正当竞争法和相关国际条约以及其他知识产权法律法规的保护。您仅能在获得授权情况下使用上述内容，而不得擅自复制或以任何其他形式进行使用。除涉及第三方授权的软件或技术外，全游云电脑产品方享有上述知识产权。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >6.2
        未经全游云电脑产品方事先明示的同意，您不得为任何营利性或非营利性的目的自行实施、利用、转让、反向工程、反向编译或反汇编、转让或许可任何三方实施上述知识产权，全游云电脑产品方保留追究上述未经许可行为之法律责任的权利。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >6.3
        未经全游云电脑产品方或相关权利人书面同意，您不得擅自使用、修改、全部或部分复制、公开传播、改变、散布、发行或公开发表、转载、引用、链接、抓取或以其他方式使用全游云电脑产品的上述信息内容。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >6.4本公司不保证全游云电脑产品上由其他权利人提供的所有内容没有侵犯任何第三方的合法权益，如您认为前述内容侵犯您的合法权益，请及时与本公司联系。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第七条：免责声明与法律信息</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >7.1 </span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >全游云电脑中的链接、应用和服务若系第三方制作或提供，其中涉及的立场与主张均由内容提供方自身负责，并不代表全游云电脑方的立场；用户可以从此获得资讯及享用服务。对于全游云电脑中的链接、应用和服务的准确性、完整性、安全性、真实性和适用性，全游云电脑方对此不承担任何服务及法律责任。用户通过全游云电脑向网络或者其他无论公开或者私密的主体发送任何信息，都与全游云电脑方无关，系用户自身的立场和主张。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >7.2 </span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >任何单位或个人认为全游云电脑中的内容可能涉嫌侵犯其合法权益，应该及时向全游云电脑方书面反馈，并提供身份证明、权属证明及详细侵权情况证明，在收到上述法律文件并审核确认后，将会尽快协调协助移除被控侵权内容。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >7.3 用户可以通过产品界面上的联系方式与我们取得联系。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第八条：通知和送达</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >8.1本协议涉及需要向全游云电脑产品方的联系、通知以本协议第七条第三款规定为准。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >8.2本协议项下所有向您或您的客户通知均可通过重要页面公告（弹出窗口等）、</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >用户短信、微信公众号信息、</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >电子邮件或常规的信件传送等方式进行，该通知于发送之日或签收之日视为已送达用户。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第九条：管辖、适用法律</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >9.1本协议的成立、生效、解释和执行均适用中华人民共和国法律，并受其管辖。本协议中提及的任何法律、法规、条例、通知或者法定条款应包括立法机关对其作出的任何补充、修订或重新颁布。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >9.2因本协议或/和本协议的履行引起的任何分歧和争议，双方应当友好协商解决。协商不成的，应提交杭州市拱墅区人民法院通过诉讼解决。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >9.3若本协议中的任何条款被判定为无效或不可执行，均不影响本协议其余条款的有效性或可执行性。如果本协议的任何条款被任何司法机关或者其他有权的机关认定为无效或者不可执行，则本协议的所有其余条款将仍然完全有效且不以任何方式受到损害。如果本协议的任何条款被认定为无效或者不可执行但在删除该条款的某些部分后将为有效或者可以执行，则双方将通过对该条款进行最小范围的必要修改使其有效和可执行。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第十条：生效</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >本协议自您注册完成时生效并依据本协议条款对您产生约束力。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal"
        >&#xa0;</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: right">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >杭州子默网络科技有限公司</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: right">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal">
        2022年7月1日</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p style="margin: 5px 0px; text-align: justify">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      >
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
